import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SeedData } from '../models/seed-data';
import { AuthService } from './auth.service';
import { User } from '../models/user';
import { UserNotLogged } from '../models/user-not-logged';
import { BillingRequest } from '../models/request/billing-request';
import { USER_TYPE } from '../utils/routes';
import {IColor} from '../models/color';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl = `${environment.apiUrl}v1/`;
  private seed: BehaviorSubject<SeedData> = new BehaviorSubject<SeedData>(null);
  private profile: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private userNotLogged: BehaviorSubject<UserNotLogged> = new BehaviorSubject<UserNotLogged>(null);
  private billing: BehaviorSubject<BillingRequest> = new BehaviorSubject<BillingRequest>(null);

  public profile$ = this.profile.asObservable();
  public userNotLogged$ = this.userNotLogged.asObservable();
  public billing$ = this.billing.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) { }

  public get userNotLoggedData() {
    return this.userNotLogged.getValue();
  }

  public set userNotLoggedData(data: UserNotLogged) {
    this.userNotLogged.next(data);
  }

  public get billingData() {
    return this.billing.getValue();
  }

  public set billingData(data: BillingRequest) {
    this.billing.next(data);
  }

  public getSeedData(): Observable<SeedData> {
    if (!this.authService.getAccessToken()) {
      return of({ userType: USER_TYPE.student }).pipe(
        tap(_ => {
          this.seedData = _;
          this.authService.logged = false;
        })
      );
    }

    return this.http.get<SeedData>(`${this.baseUrl}seed`)
      .pipe(
        tap(_ => {
          this.seedData = _;
          this.authService.logged = true;
        }),
        catchError(e => {
          this.authService.signOut();
          this.profileData = null;
          this.seedData = { userType: USER_TYPE.student };
          throw e;
        })
      );
  }

  public get seedData() {
    return this.seed.getValue();
  }

  public set seedData(seed: SeedData) {
    this.seed.next(seed);
  }

  public getProfile(): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}profile`).pipe(
      retry(environment.retryService),
      tap(_ => this.profileData = _)
    );
  }

  public get profileData() {
    return this.profile.getValue();
  }

  public set profileData(profile: User) {
    this.profile.next(profile);
  }

  public updateProfile(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}profile`, formData).pipe(
      retry(environment.retryService),
      tap(_ => this.profileData = _)
    );
  }

  public validateEmail(email: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}student/validateEmail`, { email }).pipe(
      retry(environment.retryService)
    );
  }

  public getColors(): Observable<IColor[]> {
    return this.http.get<IColor[]>(`${this.baseUrl}teacher/landing/colors`).pipe(
      retry(environment.retryService)
    );
  }

  public updateLandingPageData(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}teacher/landing/registerLanding`, formData).pipe(
      retry(environment.retryService)
    );
  }

  public newStudent(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}admin/student`, formData).pipe(
      retry(environment.retryService)
    );
  }
}
