// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://api.ciro.pe/api/", //'http://127.0.0.1:8000/api/',//
  accessTokenApi: "el_access_token",
  accestTypeUser: "el_access_type",
  couponCode: "el_coupon_code",
  courseFree: "el_course_free",
  idCourse: "el_course",
  retryService: 3,
  mercadoPagoKey: "APP_USR-d342cf1a-fb03-4c01-9309-626717ef8cc4",
  apiSecretZoom: "s0nxK6xiHUCdRkePaZqK5IgQ3UCSmHT8J4rY",
  apiKeyZoom: "oe4TRqt7RR2R9Go_r0Wcvw",
  domain: "http://localhost:4200/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
