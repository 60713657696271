import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PrefixPhone } from '../models/prefix-phone';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private baseUrl = `${environment.apiUrl}v1/`;

  private showLoading = new BehaviorSubject<boolean>(false);
  private showSidebar = new BehaviorSubject<boolean>(false);
  private _listPrefixPhones: PrefixPhone[] = [];
  private _listCountries: any[] = [];

  public showLoading$ = this.showLoading.asObservable();
  public showSidebar$ = this.showSidebar.asObservable();

  constructor(private http: HttpClient) { }

  set showLoadingCurrent(value: boolean) {
    this.showLoading.next(value);
  }

  set showSidebarCurrent(show: boolean) {
    this.showSidebar.next(show);
  }

  set listPrefixPhones(list: PrefixPhone[]) {
    this._listPrefixPhones = list;
  }

  get listPrefixPhones() {
    return this._listPrefixPhones;
  }

  set listCountries(list: any[]) {
    this._listCountries = list;
  }

  get listCountries() {
    return this._listCountries;
  }

  public getPrefixPhones(): Observable<PrefixPhone[]> {
    return this.http.get<PrefixPhone[]>(`${this.baseUrl}countries`)
      .pipe(
        tap(_ => this.listPrefixPhones = _)
      );
  }

  public getCountries(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}tefacturo/countries`)
      .pipe(
        tap(_ => this.listCountries = _)
      );
  }

  public setCouponCode(coupon: string) {
    localStorage.setItem(environment.couponCode, coupon);
  }

  public getCouponCode(): string {
    if (localStorage.getItem(environment.couponCode)) {
      return localStorage.getItem(environment.couponCode);
    }
    return null;
  }

  public removeCouponCode() {
    localStorage.removeItem(environment.couponCode);
  }

}
