export const STUDENT_MODULE = '';
export const TEACHER_MODULE = 'teacher';
export const ADMIN_MODULE = 'admin';
export const ERROR_SERVER = 'error-internal';
export const ERROR_NOT_FOUND = 'error-not-found';

export const STUDENT_PAGES = {
  profile: 'profile',
  course: 'learn/course',
  listCourses: '',
  detailCourse: 'course',
  myCourses: 'course/my-courses'
};

export const TEACHER_PAGES = {
  profile: 'profile',
  curriculum: 'manage/curriculum',
  basicInformation: 'manage/basic-information',
  pricing: 'manage/pricing',
  testimony: 'manage/testimony',
  frequentQuestions: 'manage/questions',
  benefits: 'manage/benefits',
  benefitsOnline: 'manage/benefits-online',
  zoom: 'manage/zoom',
  myCourses: 'course/my-courses',
  listStudents: 'list-students',
  landingPage: 'landing-page'
};

export const ADMIN_PAGES = {
  listStudents: 'list-students',
  listPayments: 'list-payments',
  detailStudent: 'detail-student',
  historyPaymentStudent: 'history-payment-student',
  listCoupons: 'list-coupons',
  addCoupon: 'add-coupon'
};

export const USER_TYPE = {
  student: 'estudiante',
  teacher: 'docente',
  admin: 'administrador'
};

export const USER_TYPE_ROUTE: { [key: string]: string } = {
  estudiante: STUDENT_MODULE,
  docente: TEACHER_MODULE,
  administrador: ADMIN_MODULE
};

export class CustomRoutesTeacher {
  static profile() {
    return `${TEACHER_MODULE}/${TEACHER_PAGES.profile}`;
  }

  static manageCurriculum(idCourse: number) {
    return `${TEACHER_MODULE}/${idCourse}/${TEACHER_PAGES.curriculum}`;
  }

  static manageBasicInformation(idCourse: number) {
    return `${TEACHER_MODULE}/${idCourse}/${TEACHER_PAGES.basicInformation}`;
  }

  static managePricing(idCourse: number) {
    return `${TEACHER_MODULE}/${idCourse}/${TEACHER_PAGES.pricing}`;
  }

  static testimony(idCourse: number) {
    return `${TEACHER_MODULE}/${idCourse}/${TEACHER_PAGES.testimony}`;
  }

  static frequentQuestions(idCourse: number) {
    return `${TEACHER_MODULE}/${idCourse}/${TEACHER_PAGES.frequentQuestions}`;
  }

  static benefits(idCourse: number) {
    return `${TEACHER_MODULE}/${idCourse}/${TEACHER_PAGES.benefits}`;
  }

  static benefitsOnline(idCourse: number) {
    return `${TEACHER_MODULE}/${idCourse}/${TEACHER_PAGES.benefitsOnline}`;
  }

  static zoom(idCourse: number) {
    return `${TEACHER_MODULE}/${idCourse}/${TEACHER_PAGES.zoom}`;
  }

  static myCourses() {
    return `${TEACHER_MODULE}/${TEACHER_PAGES.myCourses}`;
  }

  static listStudents(idCourse: number) {
    return `${TEACHER_MODULE}/course/${idCourse}/${TEACHER_PAGES.listStudents}`;
  }

  static landingPage() {
    return `${TEACHER_MODULE}/${TEACHER_PAGES.landingPage}`;
  }
}

export class CustomRoutesStudent {
  static profile() {
    return `${STUDENT_MODULE}/${STUDENT_PAGES.profile}`;
  }

  static myCourses() {
    return `${STUDENT_MODULE}/${STUDENT_PAGES.myCourses}`;
  }

  static listCourses() {
    return `${STUDENT_MODULE}/${STUDENT_PAGES.listCourses}`;
  }

  static detailCourse(aliasCourse: string) {
    return `${STUDENT_MODULE}/${STUDENT_PAGES.detailCourse}/${aliasCourse}`;
  }

  static zoom(aliasCourse: string) {
    return `${STUDENT_MODULE}/${STUDENT_PAGES.detailCourse}/${aliasCourse}/live-zoom`;
  }

  static detailCourseLearn(aliasCourse: string, idLesson: number) {
    return `${STUDENT_MODULE}/${STUDENT_PAGES.course}/${aliasCourse}/lesson/${idLesson}`;
  }
}

export class CustomRoutesAdmin {
  static listStudents() {
    return `${ADMIN_MODULE}/${ADMIN_PAGES.listStudents}`;
  }

  static listPayments() {
    return `${ADMIN_MODULE}/${ADMIN_PAGES.listPayments}`;
  }

  static detailStudent(idStudent: number) {
    return `${ADMIN_MODULE}/${ADMIN_PAGES.detailStudent}/${idStudent}`;
  }

  static historyPaymentStudent(idStudent: number) {
    return `${ADMIN_MODULE}/${ADMIN_PAGES.historyPaymentStudent}/${idStudent}`;
  }

  static listCoupons() {
    return `${ADMIN_MODULE}/${ADMIN_PAGES.listCoupons}`;
  }

  static addCoupon() {
    return `${ADMIN_MODULE}/${ADMIN_PAGES.addCoupon}`;
  }
}
