import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { map, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ERROR_NOT_FOUND, ERROR_SERVER } from './shared/utils/routes';
import { AuthService } from './shared/services/auth.service';
import { UtilService } from './shared/services/util.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private utilService: UtilService,
    private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let newReq: any;

    if (this.authService.getAccessToken()) {
      newReq = req.clone({
        setHeaders: { Authorization: `Bearer ${this.authService.getAccessToken()}` },
      });
    } else {
      newReq = req.clone({
        // setHeaders: { 'Content-Type': 'application/json' }
      });
    }

    if (req.url.includes('/teacher/course/') && (req.url.includes('/video') || req.url.includes('/resource/file')) || req.url.includes('/seed')) {
      this.utilService.showLoadingCurrent = false;
    } else {
      this.utilService.showLoadingCurrent = true;
    }

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event.body) {
          event.body = event.body.data;
          return event;
        }

        return event;
      }),
      catchError(error => {
        switch (error.status) {
          case 404: {
            // this.router.navigate([ERROR_NOT_FOUND]);
            break;
          }
          case 500: {
            // this.router.navigate([ERROR_SERVER]);
            break;
          }
          default: {
            break;
          }
        }

        throw error;
      }),
      finalize(() => this.utilService.showLoadingCurrent = false)
    );
  }
}