import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { SeedResolver } from './shared/services/seed-resolver.service';
import { AuthRouteGuard } from './shared/guards/auth-route.guard';
import { UserService } from './shared/services/user.service';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 68]
};

const routes: Routes = [
  {
    path: '',
    resolve: { seedData: SeedResolver },
    children: [
      {
        path: '',
        loadChildren: () => import('./user/student/student.module').then(m => m.StudentModule)
      },
      {
        canActivate: [AuthRouteGuard],
        path: 'teacher',
        loadChildren: () => import('./user/teacher/teacher.module').then(m => m.TeacherModule)
      },
      {
        canActivate: [AuthRouteGuard],
        path: 'admin',
        loadChildren: () => import('./user/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [
    SeedResolver,
    AuthRouteGuard,
    UserService
  ]
})
export class AppRoutingModule { }
