import { Component, OnInit, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { LoadingWindowComponent } from './shared/components/loading-window/loading-window.component';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => fbq('track', 'PageView'));
  }

  ngOnInit() {
    setTimeout(() => {
      const componentRef = this.componentFactoryResolver.resolveComponentFactory(LoadingWindowComponent).create(this.injector);
      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as EmbeddedViewRef<LoadingWindowComponent>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    }, 0);
  }
}
