import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { SeedData } from '../models/seed-data';

@Injectable()
export class SeedResolver implements Resolve<SeedData> {
  constructor(private userService: UserService) { }

  resolve(): Observable<SeedData> {
    return this.userService.getSeedData();
  }
}