import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { TokenResponse } from '../models/response/token-response';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = `${environment.apiUrl}v1/`;
  private _logged = new BehaviorSubject<boolean>(false);

  public logged$ = this._logged.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router) { }

  set logged(value: boolean) {
    this._logged.next(value);
  }

  public login(user: User): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.baseUrl}login`, user)
      .pipe(
        retry(environment.retryService)
      );
  }

  public forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}resetPassword`, { email })
      .pipe(
        retry(environment.retryService)
      );
  }

  public registerStudent(user: User): Observable<any> {
    return this.http.post<TokenResponse>(`${this.baseUrl}student`, user)
      .pipe(
        retry(environment.retryService)
      );
  }

  public getAccessToken(): string {
    if (localStorage.getItem(environment.accessTokenApi)) {
      return localStorage.getItem(environment.accessTokenApi);
    }
    return null;
  }

  public getAccessTypeUser(): string {
    if (localStorage.getItem(environment.accestTypeUser)) {
      return localStorage.getItem(environment.accestTypeUser);
    }
    return null;
  }

  public saveToken(token: TokenResponse) {
    localStorage.setItem(environment.accessTokenApi, token.token);
  }

  public signOut() {
    localStorage.removeItem(environment.accessTokenApi);
    this.logged = false;
    this.router.navigate(['/']);
  }
}
