import { Component, OnInit, HostBinding } from '@angular/core';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-loading-window',
  templateUrl: './loading-window.component.html',
  styleUrls: ['./loading-window.component.scss']
})
export class LoadingWindowComponent implements OnInit {

  @HostBinding('class.active') isActive = false;

  constructor(private utilService: UtilService) { }

  ngOnInit() {
    this.utilService.showLoading$.subscribe(value => this.isActive = value);
  }

}
